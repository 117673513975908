module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"head":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-relative-images"},{"resolve":"gatsby-remark-images","options":{"maxWidth":2048}},{"resolve":"gatsby-remark-copy-linked-files","options":{"destinationDir":"static"}},"gatsby-remark-prismjs",{"resolve":"gatsby-remark-jh-inline-svg","options":{"colors":{"#8D8DA6":"var(--illustrationHeroShapeBackground)","#707073":"var(--illustrationHeroShapeBackground)","#DEDEE6":"var(--illustrationLineShade1)","#D4D4DD":"var(--illustrationLineShade2)","#C9C9D6":"var(--illustrationLineShade3)","#C1C1D2":"var(--illustrationLineShade3)","#F9F9FB":"var(--backgroundSecondary)","#2E3138":"var(--text)","#FFFFFF":"var(--backgroundPrimary)","#D75037":"var(--accent)"}}},"gatsby-remark-jh-codepen-block"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Florin Catalin | Product, Design, Engineering","short_name":"F. Catalin","description":"The personal website, portfolio, and blog of Florin Catalin.","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"src/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"NetworkOnly"}]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
